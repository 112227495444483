<template>
  <div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div>
        <div class="card mb-4">
          <div class="card-header"></div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-lg-6">
                <b-button
                  @click="RedirectToAddProduct"
                  class="col-3 col-lg-3"
                  variant="outline-dark"
                  >Ajouter Produits
                </b-button>
              </div>
              <div class="col-12 col-lg-3">
                <h6>Recherche Produits :</h6>
                <b-form-input
                  placeholder="Product Name"
                  v-model="searchQuery"
                />
              </div>
              <div class="col-12 col-lg-3" style="margin-top: 23px">
                <div class="row">
                  <button
                    @click="SearchQuery()"
                    class="col-6 col-lg-6 btn btn-primary ml-1"
                  >
                    Recherche Produits
                  </button>

                  <button
                    @click="clearSearchQuery()"
                    class="col-4 col-lg-4 btn btn-secondary ml-1"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
            <h4 style="margin-top: 50px">Filtrage</h4>
            <div class="row" style="margin-top: 30px">
              <div class="col-12 col-lg-3">
                <h6>Categorie :</h6>
                <v-select
                  v-model="categorieQuery"
                  :options="flattenedCategories"
                  label="formattedName"
                >
                </v-select>
              </div>

              <div class="col-12 col-lg-3" style="margin-top: 23px">
                <div class="row">
                  <button
                    @click="searchCategorieQuery()"
                    class="col-6 col-lg-6 btn btn-primary ml-1"
                  >
                    Filtrage Produits
                  </button>

                  <button
                    @click="clearCategorieQuery()"
                    class="col-4 col-lg-4 btn btn-secondary ml-1"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-table
        :items="items"
        :fields="fields"
        striped
        hover
        responsive
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style="margin-left: 5px">Loading...</strong>
          </div>
        </template>

        <template #cell(categories)="row">
          <div v-for="item in row.item.categories" :key="item.id">
            <b-badge
              style="margin: 5px"
              pill
              variant="light-dark"
              class="text-capitalize"
            >
              {{ item.name }}
            </b-badge>
          </div>
        </template>
        <template #cell(status)="row">
          <b-badge
            pill
            :variant="`light-${resolveStatusProduct(row.item.status)}`"
            class="text-capitalize"
          >
            {{ row.item.status }}
          </b-badge>
        </template>

        <template #cell(product)="row" style="width: 100px">
          <ProductTableCell
            :imageSrc="row.item.firstImage"
            :productName="row.item.name"
            :productDescription="row.item.short_description"
          />
        </template>
        <template #cell(name)="row">
          <span class="font-weight-bold d-block">
            {{ row.item.name }}
          </span>

          <small class="text-muted"> SKU:{{ row.item.sku }}</small>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row2-${data.item.id}-send-icon`"
              icon="EditIcon"
              class="cursor-pointer"
              size="16"
              @click="RedirectToEditProduct(data.item)"
            />
            <b-tooltip
              title="Modification Produit"
              :target="`invoice-row2-${data.item.id}-send-icon`"
            />

            <feather-icon
              :id="`invoice11-row-${data.item.id}-preview-icon`"
              :icon="data.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'"
              size="16"
              class="mx-1"
              @click="openURLInNewTab(data.item)"
            />
            <b-tooltip
              title="Page Produit"
              :target="`invoice11-row-${data.item.id}-preview-icon`"
            />
            <feather-icon
              :id="`invoice-row-${data.item.id}-trash-icon`"
              icon="TrashIcon"
              @click="ConfirmeDelete(data.item.id)"
            />
            <b-tooltip
              title="Supprimer"
              :target="`invoice-row-${data.item.id}-trash-icon`"
            />
          </div>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
        aria-controls="product-list"
        size="md"
        align="center"
        class="my-4"
        @change="handlePageChange"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  AddProduct,
  getParentCategories,
} from "../../services/products/Products";
import useStatut from "./useStatut";
import ProductTableCell from "./ProductTableCell.vue";
import {
  BPagination,
  BFormInput,
  BCardText,
  BButton,
  BIcon,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BAvatar,
  BTable,
  BSpinner,
  BImg,
  BLink,
  BTooltip,
  bIcon,
  BOverlay,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import websiteURL from "@/websiteURL";
export default {
  components: {
    BPagination,
    BFormInput,
    BCardText,
    vSelect,
    BSpinner,
    BTooltip,
    BOverlay,
    BDropdownItem,
    BDropdown,
    BIcon,
    ProductTableCell,
    BTable,
    BImg,
    BLink,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
  },
  setup() {
    const {
      resolveColorEtatProduct,
      resolveTextEtatProduct,
      resolveStatusProduct,
      resolveTypeProduct,
    } = useStatut();

    return {
      resolveColorEtatProduct,
      resolveTextEtatProduct,
      resolveStatusProduct,
      resolveTypeProduct,
    };
  },
  data() {
    return {
      loading: false,
      lastProductId: null,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      categorieQuery: null,
      searchQuery: null,
      categories: [],
      isBusy: false,
      mainProps: {
        width: 75,
        height: 75,
        class: "m1",
      },
      items: [],
      fields: ["product", "name", , "categories", "status", "actions"],
    };
  },
  computed: {
    flattenedCategories() {
      const flattened = [];

      for (const category of this.categories) {
        flattened.push({
          ...category,
          formattedName: `* ${category.name}`,
          disabled: true,
        });

        if (category.subcategories && category.subcategories.length > 0) {
          for (const subcategory of category.subcategories) {
            flattened.push({
              ...subcategory,
              formattedName: `     \u00A0 - ${subcategory.name}`,
              disabled: false,
            });
          }
        }
      }

      return flattened;
    },
  },
  methods: {
    RedirectToAddProduct() {
      this.$router.push({
        name: "Add Product Simple",
      });
    },
    ConfirmeDelete(productId) {
      this.boxMsgDelete = "";
      this.$bvModal
        .msgBoxConfirm("Êtes-vous sûr de vouloir supprimer le Produit ?", {
          title: "Confirmation",
          size: "sm",
          okVariant: "primary",
          okTitle: this.loading ? "Loading..." : "Confirmer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.boxMsgDelete = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.deleteProduct(productId).then(async () => {
                this.loading = false;
              });
            }
          }
        });
    },
    async deleteProduct(productId) {
      const apiUrl = websiteURL.WEBSITE + `wp-json/wc/v3/products/${productId}`;
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      try {
        const response = await axios.delete(apiUrl, {
          params: {
            force: true,
          },
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        await this.fetchProducts();
      } catch (error) {
        alert(
          "Erreur lors de la suppression du produit, veuillez réessayer plus tard",
          error
        );
      }
    },
    openURLInNewTab(url) {
      window.open(url.product.permalink, "_blank");
    },
    handlePageChange(event) {
      if (event === null) {
        console.log("No page found");
      } else {
        this.currentPage = event;
        this.fetchProducts(this.searchQuery, this.categorieQuery);
      }
    },
    async searchCategorieQuery() {
      this.currentPage = 1;
      this.fetchProducts(this.searchQuery, this.categorieQuery);
    },
    async clearCategorieQuery() {
      this.categorieQuery = null;
      this.currentPage = 1;
      this.fetchProducts(this.searchQuery, this.categorieQuery);
    },
    async SearchQuery() {
      this.currentPage = 1;
      this.fetchProducts(this.searchQuery, this.categorieQuery);
    },
    async clearSearchQuery() {
      this.searchQuery = null;
      this.currentPage = 1;
      this.fetchProducts(this.searchQuery, this.categorieQuery);
    },
    async RedirectToEditProduct(product) {
      if (product.type === "variable") {
        this.$router.push({
          name: "Edit-Product-Variable",
          params: { id: product.id },
        });
      } else if (product.type === "simple") {
        this.$router.push({
          name: "Edit-Product",
          params: { id: product.id },
        });
      }
    },
    formatName(name) {
      const words = name.split(" ");

      const lines = [];

      for (let i = 0; i < words.length; i += 3) {
        const line = words.slice(i, i + 3).join(" ");
        lines.push(line);
      }

      return lines.join("<br>");
    },
    async fetchProducts(searchQuery, categorieQuery) {
      (this.items = []), (this.isBusy = true);
      const apiUrl = websiteURL.WEBSITE + "wp-json/wc/v3/products";
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;

      const params = {
        per_page: this.perPage,
        page: this.currentPage,
      };

      if (searchQuery) {
        params.search = searchQuery;
      }

      if (categorieQuery) {
        params.category = categorieQuery.id;
      }

      try {
        const response = await axios.get(apiUrl, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
          params,
        });

        this.totalItems = response.headers["x-wp-total"];

        this.items = this.items.concat(
          response.data.map((product) => {
            return {
              id: product.id,
              product: product,
              short_description: product.short_description,
              firstImage:
                product.images.length > 0 ? product.images[0].src : null,
              name: product.name,
              date_created: product.date_created,
              sku: product.sku,
              stock_status: product.stock_status,
              status: product.status,
              etat: product.on_sale,
              categories: product.categories,
              brand: product.brand,
              type: product.type,
            };
          })
        );

        this.isBusy = false;
      } catch (error) {
        console.error("Error fetching products:", error);
        this.items = [];
      }
    },
  },

  async mounted() {
    await this.fetchProducts();
    this.categories = await getParentCategories();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
